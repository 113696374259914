import React, { useState } from 'react';
import { Typography } from 'antd';
import MapGL, { Marker, NavigationControl } from '@urbica/react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';
import { FaMapMarkerAlt } from 'react-icons/all';
import css from './map.module.less';

const { Title } = Typography;

export const Map = ({
  isStatic,
  products,
}) => {
  const initLat = products[0]?.location?.geography.coordinates[0] || 48;
  const initLng = products[0]?.location?.geography.coordinates[1] || 45;
  const initZoom = products[0]?.location?.geography.coordinates[0] ? 13 : 3;

  const [viewport, setViewport] = useState({
    latitude: initLat,
    longitude: initLng,
    zoom: initZoom,
  });

  return (
    <MapGL
      className={isStatic ? css.static : css.map}
      mapStyle="mapbox://styles/akommo/ckiutkpgg37tw19qk0eb5rm1e"
      accessToken={process.env.GATSBY_MAPBOX_TOKEN}
      viewportChangeMethod="flyTo"
      onViewportChange={isStatic ? null : setViewport}
      zoom={viewport.zoom}
      latitude={viewport.latitude}
      longitude={viewport.longitude}
      attributionControl={false}
    >
      <NavigationControl showCompass showZoom position="top-left" />
      {products?.map((product) => {
        if (product.location?.geography) {
          return (
            <Marker
              key={product.id}
              latitude={product.location?.geography.coordinates[0]}
              longitude={product.location?.geography.coordinates[1]}
            >
              <Title level={2}>
                <FaMapMarkerAlt />
              </Title>
            </Marker>
          );
        }
        return null;
      })}
    </MapGL>
  );
};

Map.defaultProps = {
  isStatic: false,
  products: [],
};

Map.propTypes = {
  isStatic: PropTypes.bool,
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    location: PropTypes.shape({
      geography: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(PropTypes.number),
      }),
    }),
  })),
};
