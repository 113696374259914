import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Tag, Tooltip,
} from 'antd';
import { Link } from 'gatsby';
import css from './carousel-card.module.less';
import { AddToFavoritesButton } from '../../favorites/add-to-favourites-button/add-to-favourites-button';
import { ImageCarousel } from '../../carousel/carousel';
import { getMetaFromAttr } from './utils/get-meta-from-attr';
import { Map } from '../../map/map';

const { Meta } = Card;
// Will construct a link if both productType and slug are provided

export const CarouselCard = ({
  variantId,
  variantCount,
  attributes,
  images,
  loading,
  productType,
  productSlug,
  productLocation,
  productName,
}) => {
  let metaTitle = productName;
  let metaDescription = [];

  if ((!productName || productType.slug === 'apartment' || productType.slug === 'villa')
    && attributes?.length > 0) {
    [metaTitle, metaDescription] = getMetaFromAttr(attributes);
  }

  if (productType) {
    metaDescription.unshift(<Tag key={productType.slug}>{productType.name}</Tag>);
  }

  if (productLocation) {
    const { city } = productLocation.address;
    metaDescription.unshift(<Tag key={city}>{city}</Tag>);
  }

  const cover = images?.length > 0 ? (
    <ImageCarousel
      images={images}
    />
  ) : (
    <Map
      isStatic
      products={[
        {
          variantId,
          productLocation,
        }]}
    />
  );

  const action = variantCount > 1 ? (
    <Tooltip title="Click to see options">
      <Button
        size="large"
      >
        {variantCount}
      </Button>
    </Tooltip>

  ) : (
    <AddToFavoritesButton
      variantId={variantId}
    />
  );

  const card = (
    <Card
      hoverable
      loading={loading}
      className={css.card}
      cover={loading ? null : cover}
    >
      {action}
      <Meta
        title={metaTitle}
        className={css.meta}
        description={metaDescription}
      />
    </Card>
  );

  if (productType?.slug && productSlug) {
    return (
      <Link to={`/${productType?.slug}/${productSlug}`}>
        {card}
      </Link>
    );
  }

  return (
    card
  );
};

CarouselCard.defaultProps = {
  loading: false,
  productLocation: undefined,
  productSlug: undefined,
  productType: undefined,
  productName: undefined,
  attributes: [],
  images: [],
  variantCount: 1,
};

CarouselCard.propTypes = {
  variantId: PropTypes.string.isRequired,
  variantCount: PropTypes.number,
  productName: PropTypes.string,
  attributes: PropTypes.arrayOf(PropTypes.shape({
    attribute: PropTypes.shape({
      name: PropTypes.string,
      inputType: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  })),
  images: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  })),
  productType: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  productSlug: PropTypes.string,
  loading: PropTypes.bool,
  productLocation: PropTypes.shape({
    geography: PropTypes.shape({
      type: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
    address: PropTypes.shape({
      city: PropTypes.string,
      postalCode: PropTypes.string,
    }),
  }),
};
