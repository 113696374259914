import { Tag } from 'antd';
import React from 'react';

export const getMetaFromAttr = (attrArray) => {
  let metaTitle = '';
  const metaDescription = [];

  attrArray.forEach((attr) => {
    const { name, inputType } = attr.attribute;
    const { values } = attr;
    if (values.length > 0) {
      if (inputType === 'DROPDOWN') {
        const { name: value } = values[0];
        if (value !== 'None') {
          let tempString = '';
          if (value === 'Studio') {
            tempString = value;
          } else if (value === '1') {
            tempString = `${value} ${name.slice(0, -1)}`;
          } else {
            tempString = `${value} ${name}`;
          }
          tempString += ' - ';
          metaTitle += tempString;
        }
      } else {
        //  else multiselect
        values.forEach(({ name: value }) => {
          metaDescription.push(<Tag key={value}>{value}</Tag>);
        });
      }
    }
  });

  return [metaTitle.slice(0, -3), metaDescription.slice(0, 3)];
};
