import React from 'react';
import PropTypes from 'prop-types';
import { Button, Carousel, Image } from 'antd';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/all';
import css from './carousel.module.less';

const LeftBtn = ({ onClick }) => (
  <Button onClick={onClick} shape="circle" className="slick-arrow slick-prev">
    <AiOutlineLeft />
  </Button>
);

const RightBtn = ({ onClick }) => (
  <Button onClick={onClick} shape="circle" className="slick-arrow slick-next">
    <AiOutlineRight />
  </Button>
);

export const ImageCarousel = ({
  images,
  isLarge,
  preview,
}) => {
  let slidesToShow = 1;
  let carouselClass = css.carousel;

  if (images.length > 1 && isLarge) {
    slidesToShow = 2;
  }

  if (isLarge) {
    carouselClass = `${css.carousel} ${css.large}`;
  }

  return (
    <Carousel
      arrows
      className={carouselClass}
      draggable
      lazyLoad
      speed="225"
      slidesToShow={slidesToShow}
      responsive={[{
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      }]}
      prevArrow={<LeftBtn />}
      nextArrow={<RightBtn />}
    >
      {images.length > 0 ? images.map(
        ({
          url,
          alt,
        }) => (
          <Image
            placeholder
            width="100%"
            height={isLarge ? 600 : 275}
            key={url}
            src={url}
            alt={alt || 'Product Image'}
            preview={preview}
          />
        ),
      ) : (
        <Image
          width="100%"
          height={isLarge ? 600 : 275}
          placeholder
        />
      )}
    </Carousel>
  );
};

ImageCarousel.defaultProps = {
  isLarge: false,
  preview: false,
};

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
  ).isRequired,
  isLarge: PropTypes.bool,
  preview: PropTypes.bool,
};
